import TabContainerElement from '@github/tab-container-element';
import client from '@/lib/api/client';
import { globalPreviewPath } from '@/lib/rails-routes';

const init = (): void => {
  const elements = document.querySelectorAll<TabContainerElement>('.js-markdown-preview');
  if (elements.length === 0) return;

  // eslint-disable-next-line no-restricted-syntax
  for (const element of elements as any) {
    // SEE: https://github.com/github/tab-container-element
    element.addEventListener('tab-container-changed', async (event: any) => {
      const target = (event as any).detail.relatedTarget as HTMLDivElement;
      const contentElement = target.querySelector('.js-markdown-preview-content');
      if (!contentElement) return;

      const textarea = element.querySelector('textarea')!;
      const { data } = await client.patch<string>(globalPreviewPath(), {
        body: textarea.value,
      });
      contentElement.innerHTML = data;
    });
  }
};

document.addEventListener('DOMContentLoaded', () => {
  init();
});
